import React from 'react';
import Left from "./assets/images/left.jpg"
// import Right from ".\src\Assets\images\weddingringchange.png" 
import Right from "../../../Assets/images/weddingringchange.png" 

import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom'

const ImageSection = () => {
  return (
    <section className='rcs_image_section overflow-hidden  section-space'>
        <Row>
            <Col lg={6} md={6} className='mb-md-0 mb-3'>
            <Link to = "/engagement-rings">  <Image src={Left} alt='ring' /> </Link>
                <div className='ej_rings_imgs'>
                    <h5>Engagement Rings </h5>
                    <p>Propose to your love with the finest engagement rings in Chicago</p>
                    <Link to = "/engagement-rings" className="btn outline-base-btn">Explore Now</Link>
                  </div>
            </Col>
            
            <Col lg={6} md={6}>
            <Link to = "/jewelry/wedding-rings">  <Image src={Right} alt='necklace' /> </Link>
                <div className='ej_rings_imgs'>
                    <h5>Wedding Rings</h5>
                    <p>Begin your forever with amazing wedding rings and bands in Chicago
                    </p>
                    <Link to = "/jewelry/wedding-rings" className="btn outline-base-btn">Explore Now</Link>
                  </div>
            </Col>
        </Row>
    </section>
  )
}

export default ImageSection